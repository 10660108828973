// External dependencies on EveryScape.SyncV2.FloorPlanViewer, EveryScape.SyncV2.GMap
import { buildFeatures, buildMobileListing, buildBuildingInfoSection, BottomDrawerTab, formatBuildingAddress, initializeBottomDrawerTabComplex, makeListCell, populateUDGInfo, getListingFormattedAttributes } from "./bottom-drawer-helpers.es6.js"
import { addCommasToNumber } from "../utils/ui-helpers.es6.js"
import { IrUtils, isNully, isNullyOrEmptyString } from "../utils/utils.es13.js"
import React from "react"
import { createRoot } from 'react-dom/client'
import { MapSearch } from "../components/MapSearch.jsx"
import { UNIT_TYPES } from "../models/content-model.es6.js"
import { DynamicDataElement, DynamicDataSequence } from "../utils/dynamic-data-sequence.mjs"
import { TTS_SOURCES } from "../speech/text-to-speech.mjs"
import stringInject from 'stringinject';

const CLICK = 'click';
const CURRENT_ITEM = 'current-item';
const DATA_LISTINGID = 'listingId';
const ITEMCELL = 'item-cell';
const DOT_ITEMCELL = '.' + ITEMCELL;
const SPACEFEATURES = 'spaceFeatures';
const DOT_SPACEFEATURES = '.' + SPACEFEATURES;

/**
 * Rendering and DOM interaction relating to the bottom drawer.
 * Basically everything is rerendered on content/project change.
 * The top portion has:
 *   - a listing name/info area; rerendered on listing change
 *   - list of features; rerendered on listing change, updated/highlighted on content-item selection
 *   - info list; updated (on/off) based on selected listing
 * The bottom portion has:
 *   - building name/info/photo; rerendered on building change
 *   - floor plans, amenities, neighborhoods; rendered for all buildings and updated/toggled/highlighted on building selection and listing selection
 * @class
 */
class BottomDrawerView {
	constructor(options) {
		this._aiService = options?.aiService;
		this._leadModel = options?.leadModel;
		/** @member {ContentModel} */
		this.contentModel = null;
		this.contentStrip = null;
		/** @member {EveryScape.SyncV2.FloorPlan} */
		this.floorplanViewer = null;
		/** @member {EveryScape.SyncV2.GMap} */
		this.googleMapsViewer = null;
		/** @member {boolean} */
		this.initialized = false;
		/** @member {boolean} listMode Is the bottom section showing a list (of units or amenities in a group) or
		 * showing the details of a listing (for a unit or amenity).
		 */
		this.listMode = true;

		// Controller hooks. Do not attach these to DOM elements directly (use underscore methods below).
		this.onBuildingSelected = function (_building) { return false; };
		this.onContactUsClicked = function (_evt) { return false; };
		this.onContentItemSelected = function (_contentItem) { return false; };
		this.onContentSelected = function (_content) { return false; };
		this.onListingSelected = function (_listing) { return false; };
		this.onNextButtonClick = function (_evt) { return false; };
		this.onPrevButtonClick = function (_evt) { return false; };
		this.onViewSelected = function (_view) { return false; };
		this.onRequestTts = (source, text) => { return false; };
		this.onMapSearch = function (query) { return false; };
	}

	/**
	 * Initialize DOM elements for bottom drawer. Hookup click handlers, etc.
	 * @return {BottomDrawerView}
	 */
	initializeView() {
		if (this.initialized) { return this; }
		$('#CloseBuildingSelector').unbind(CLICK).click(() => {
			$('#BuildingSelector').hide();
			$('#InfoSection').show();
		});
		$('#BuildingMenuBtn').unbind(CLICK).click(() => {
			$('#BuildingSelector').show();
			$('#InfoSection').hide();
		});
		$('#FooterContactUsButton, #ContactUs').unbind(CLICK).click(() => {
			this.onContactUsClicked();
			return false;
		});
		$('#BuildingMenuBtn').prop('disabled', '');
		$('#BottomDrawerCloseBtn').off(CLICK).on(CLICK, () => {
			this.minimizeBottomDrawer();
			return false;
		})
		return this._cacheDomElements()
			._initializeAvailabilityDialog()
			._initializeFloorplanViewer()
			._initializeTabs()
			._initializeNextPrevButtons();
	}

	openDrawer() {
		$("#BottomDrawer")
			.removeClass("translate-y-[100vh]")
			.addClass("bottom-0 animate-slideUp")
			.css({ top: '10vh' });
		$("#MultifamilyBuildingInfo, #MultifamilyBuildingMenu, #MultifamilyBuildingTabMenu, #ResidentialBuildingTabMenu, #ContentStripAndFloorPlan, #PhotoSection, #MarketingCenterBuildingTabMenu, #BuildingSection, #UDGItemsSection, #FooterSection, #BuildingMenuBtn, #BottomDrawer .request-access-button-section, #InfoSection").removeClass("hidden");
		$("#BottomDrawerIframe").addClass("hidden");
		$("#MapSearchTerm").hide();
		$("#InfoSection, #BottomDrawerMapHolder, #OverviewMapContainer").removeClass("h-full");
		$("#MapSearchPlaceList").removeClass("overflow-scroll");
		$("#BottomDrawerCloseBtn").removeClass("m-1 p-2 text-base");
		return this;
	}

	openDrawerForIframe(src) {
		$("#BottomDrawer")
			.removeClass("translate-y-[100vh]")
			.addClass("bottom-0 animate-slideUp")
			.css({ top: '10vh' });
		$("#MultifamilyBuildingInfo, #MultifamilyBuildingMenu, #MultifamilyBuildingTabMenu, #ResidentialBuildingTabMenu, #ContentStripAndFloorPlan, #PhotoSection, #MarketingCenterBuildingTabMenu, #BuildingSection, #UDGItemsSection, #FooterSection, #BottomDrawer .request-access-button-section, #InfoSection").addClass("hidden");
		$("#BottomDrawerIframe").removeClass("hidden");
		$("#MapSearchTerm").hide();
		$("#InfoSection, #BottomDrawerMapHolder, #OverviewMapContainer").removeClass("h-full");
		$("#MapSearchPlaceList").removeClass("overflow-scroll");
		$("#BottomDrawerCloseBtn").removeClass("m-1 p-2 text-base");
		$("#BottomDrawerIframe").attr("src", src);
		return this;
	}

	openDrawerForMapSearch() {
		window.mobileWebController.mainContainerFirstPanel?.resize(55);
		window.requestAnimationFrame(() => {
			const viewerBottom = Math.round($('#viewer-wrapper')[0].getBoundingClientRect().bottom);
			$("#BottomDrawer")
				.removeClass("translate-y-[100vh]")
				.addClass('bottom-0 animate-slideUp')
				.css({ top: `${viewerBottom}px` });
			$("#MultifamilyBuildingInfo, #MultifamilyBuildingMenu, #MultifamilyBuildingTabMenu, #ResidentialBuildingTabMenu, #ContentStripAndFloorPlan, #PhotoSection, #MarketingCenterBuildingTabMenu, #BuildingSection, #UDGItemsSection, #FooterSection, #BuildingMenuBtn, #BottomDrawer .request-access-button-section, #BottomDrawerIframe").addClass("hidden");
			$("#InfoSection").removeClass("hidden");
			$("#MapSearchTerm").show();
			$("#InfoSection, #BottomDrawerMapHolder, #OverviewMapContainer").addClass("h-full");
			$("#MapSearchPlaceList").addClass("overflow-scroll");
			$("#BottomDrawerCloseBtn").addClass("m-1 p-2 text-base");
		});

		return this;
	}

	openLocationTab() {
		this.buildingTabGroup.tabs[1].select();
		return this;
	}

	/**
	 * Minimize the bottom drawer to it's minimum visible size. Animated.
	 * @return {BottomDrawerView}
	 */
	minimizeBottomDrawer() {
		$("#BottomDrawer").removeClass("animate-slideUp").addClass("animate-slideDown");
		return this;
	}

	onCheckAvailabilityClick(dynamicListingData, listing) {
		const $dialog = this._$availabilityDialog;
		$dialog.dialog('open');
		$('.ui-widget-overlay').unbind('click').click(() => {
			$dialog.dialog('close');
		});
		$dialog.find('.ifp-modal-name').text(listing.Name);
		$dialog.find('.beds').text((0 === listing.Bedrooms) ? 'Studio' : `${listing.Bedrooms || '-'} Beds`);
		$dialog.find('.baths').text(`${listing.Bathrooms} Baths`);
		$dialog.find('.area').text(listing.SquareFeet);
		const minPrice = dynamicListingData.units.reduce((a, b) => a.Price < b.Price ? a : b).Price;
		$dialog.find('.ifp-modal-price').text(`Starting at \$${minPrice.toLocaleString()}`);

		const now = new Date();
		// Construct the table dynamically, based on what fields are present in the data
		const $table = $('<table>');
		const $headerRow = $('<tr>');
		$table.append($headerRow);
		const fieldMap = new Map([
			['UnitNumber', 'Apt #'],
			['FloorNumber', 'Floor'],
			['Price', 'Starting At'],
			['Deposit', 'Deposit'],
			['Available', 'Available On']
		]);

		const emptyColumns = [];
		fieldMap.forEach((value, key) => {
			if (dynamicListingData.units.filter((u) => { return !IrUtils.isNullyOrEmptyString(u[key]) }).length > 0) {
				$headerRow.append(`<th>${value}</th>`);
			} else {
				emptyColumns.push(key);
			}
		});
		$headerRow.append('<th>'); // make the column count the same as the rows below because of the lease now button
		$.unique(emptyColumns).forEach((k) => { fieldMap.delete(k) });

		for (let u of dynamicListingData.units) {
			const available = new Date(u.Available);
			const availableText = u.Available ? ((available < now) ? 'Available Now' : available.toDateString()) : '';
			const depositText = IrUtils.isNully(u.Deposit) ? '' : u.Deposit.toLocaleString();
			const $row = $('<tr>');
			if (fieldMap.has('UnitNumber')) { $row.append($('<td>').text(u.UnitNumber)); }
			if (fieldMap.has('FloorNumber')) { $row.append($('<td>').text(u.FloorNumber)); }
			if (fieldMap.has('Price')) { $row.append($('<td>').text(`\$${u.Price.toLocaleString()}`)); }
			if (fieldMap.has('Deposit')) { $row.append($('<td>').text(depositText)); }
			if (fieldMap.has('Available')) { $row.append($('<td>').text(availableText)); }
			const $leaseNowButton = $('<button>').addClass('ifp-modal-lease-now-button').data('unit', u).text('Lease Now');
			$row.append($('<td>').append($leaseNowButton));
			$leaseNowButton.click((_evt) => { return this._onOpenDatePicker(u); });
			$table.append($row);
		}

		$dialog.find('.ifp-modal-availability-section').empty().append($table);
		$dialog.find('.ifp-close-button').unbind('click').click(() => {
			$dialog.dialog('close');
		});
		return false;
	}
	/**
	 * Update entire drawer when new content (listing) has been loaded.
	 * @param {ContentModel} contentModel
	 * @return {BottomDrawerView}
	 */
	setContent(contentModel) {
		this.contentModel = contentModel;
		this._setContentBrandColors();
		this.floorplanViewer.addFloorPlanDescriptors(this.contentModel.floorPlanDescriptors);
		return this.minimizeBottomDrawer()
			._renderBottomDrawer()
			._updateBottomDrawer()
			._initializeDynamicTabs(this.contentModel.content.ProjectTypeName)
			._renderDynamicProjectDependantUI(this.contentModel.content.ProjectTypeName);
	}

	/**
	 * Update the google maps widget, as the library may now be available.
	 * @return {BottomDrawerView}
	 */
	setGoogleMapsLoaded() {
		return this._initializeGoogleMapsViewer();
	}

	/**
	 * Update any DOM elements when the selected building changes.
	 * @return {BottomDrawerView}
	 */
	setSelectedBuilding() {
		return this._renderSpaceInfoSection()
			._updateTopInfoSection()
			._renderFeaturesList()
			._renderBuildingNameAndPhoto()
			._updateBuildingSection()
			._updateMapBasis()
			.minimizeBottomDrawer();
	}

	/**
	 * Update any DOM elements when the selected content item changes.
	 * @return {BottomDrawerView}
	 */
	setSelectedContentItem() {
		return this._updateTopInfoSection()
			._updateFeaturesList()
			._matchTabsAndPanelsToSelectedContentItem()
			.minimizeBottomDrawer();
	}

	/**
	 * Update any DOM elements when the selected listing changes.
	 * @return {BottomDrawerView}
	 */
	setSelectedListing() {
		let listing = this.contentModel.getSelectedListing();
		if (!listing.PropertyUnitType) {
			//UDG
			populateUDGInfo(listing);
			return this._updateTopInfoSection()
				._renderUDGFeaturesList()
				._updateContentStripAndFloorPlan()
				.minimizeBottomDrawer();
		} else {
			//not UDG
			return this._renderSpaceNameAndBasicInfo()
				._updateTopInfoSection()
				._renderFeaturesList()
				._updateContentStripAndFloorPlan()
				.minimizeBottomDrawer();
		}
	}

	_buildPictureLink(listing) {
		const backgroundImage = listing.ThumbnailUrl ? listing.ThumbnailUrl : "/images/image_not_supported.svg";
		const $wrapper = $('<div>').addClass('placeItem amenityCell');
		$wrapper.append($('<div>').addClass('placeTile').css('backgroundImage', `url(${backgroundImage})`))
			.append($('<div>').addClass('labelText placeName oneLineText').text(listing.Name))
		return $wrapper;
	}

	/**
	 * Store references to all the DOM elements we'll use, to minimize DOM searches later.
	 * @private
	 * @return {BottomDrawerView}
	 */
	_cacheDomElements() {
		this._$additionalMCInfo = $("#AdditionalMCInfo");
		this._$buildingMenuBtn = $('#BuildingMenuBtn');
		this._$contentDescription = $('#ContentDescription');
		this._$contentItemsList = $('#ContentItemsList');
		this._$contentStripSection = $('#ContentStripSection');
		this._$contentTitle = $('#ContentTitle');
		this._$contentTitleDrawer = $('#ContentTitleDrawer');
		this._$topInfoTitle = $('#TopInfoTitle');
		this._$topInfoContext = $('#TopInfoContext');
		this._$topInfoAttributes = $('#TopInfoAttributes');
		this._$detailsSection = $('#DetailsSection');
		this._$floorPlanContainer = $('#FloorPlanContainer');
		this._$listingPhoto = $("#ListingPhoto");
		this._$mainBodyContainer = $('#MainBodyContainer');
		this._$marketingCenterBuildingTabMenu = $("#MarketingCenterBuildingTabMenu");
		this._$multifamilyBuildingInfo = $("#MultifamilyBuildingInfo");
		this._$multifamilyBuildingMenu = $("#MultifamilyBuildingMenu");
		this._$multifamilyBuildingTabMenu = $("#MultifamilyBuildingTabMenu");
		this._$nextPreviousButtons = $('#NextPreviousButtons');
		this._$nextInfoButton = $('#NextInfoButton');
		this._$prevInfoButton = $('#PrevInfoButton');
		this._$residentialBuildingInfo = $("#ResidentialBuildingInfo");
		this._$residentialBuildingTabMenu = $("#ResidentialBuildingTabMenu");
		this._$spaceInfoSection = $('#SpaceInfoSection');
		this._$spaceNameAndInfo = $('#SpaceNameAndInfo');
		this._$UDGItemsList = $('#UDGItemsList');
		return this;
	}

	_initializeAvailabilityDialog() {
		this._$availabilityDialog = $('.ifp-modal-cell').dialog({
			autoOpen: false, dialogClass: 'ifp-dialog', modal: true,
			position: ['center', 100], resizable: false, width: 'auto',
			classes: {
				'ui-dialog': 'ifp-dialog'
			}
		});
		this._$availabilityDialog.on('dialogopen', (_evt) => {
			$('#ModalHolderBehindLoader').show();
		});
		this._$availabilityDialog.on('dialogclose', (_evt) => {
			$('#ModalHolderBehindLoader').hide();
		});

		return this;
	}

	/**
	 * Initialize the tab group for the property and location/neighborhood tabs.
	 * Hooking up tab click handlers.
	 * @param {string} propertyTabButtonSelector
	 * @param {string} locationTabButtonSelector
	 * @returns {BottomDrawerView}
	 */
	_initializeBuildingTabGroup(propertyTabButtonSelector, locationTabButtonSelector) {
		this.buildingTabGroup = initializeBottomDrawerTabComplex('#MarketingCenterBuildingTabMenu', [
			{
				button: propertyTabButtonSelector,
				toggle: (on, isClick) => {
					if (on) {
						if (isClick) { this.listMode = true; }
						this._updateBuildingListModeElements();
						$('#MultifamilyBuildingMenu, .infoSection').show();
						$('.buildingGroupsSection, #BottomDrawerMapHolder, #UDGItemsSection, .neighborhoodsSection').hide();
					}
				}
			},
			{
				button: locationTabButtonSelector,
				toggle: (on, isClick) => {
					if (on) {
						if (isClick) { this.listMode = true; }
						$('#MultifamilyBuildingMenu, #ContentStripAndFloorPlan, #ListingPhoto, .buildingTabSections, #BuildingPhoto, .infoSection').hide();
						$('.buildingGroupsSection, #BottomDrawerMapHolder, #UDGItemsSection, .neighborhoodsSection').show();
					}
				}
			}
		], 0);
		return this;
	}

	/**
	 * These tabs need to be hooked up after dynamic DOM elements are created.
	 */
	_initializeDynamicTabs(projectType) {
		switch (projectType) {
			case 'Marketing Campaign':
				$('#BuildingFloorPlansTab').text('Availabilities');
				return this._initializeDynamicTabsMarketingCenter();

			case 'Residential Sales':
				return this._initializeDynamicTabsResidential();

			case 'Multi Family': // Multifamily is default
			default:
				return this._initializeDynamicTabsMultifamily();
		}
	}

	_initializeDynamicTabsMarketingCenter() {
		return this._initializeBuildingTabGroup('#MCBuildingTab', '#MCBuildingLocationTab')
			._initializeUnitsAndAmenitiesTabs();
	}

	_initializeDynamicTabsMultifamily() {
		return this._initializeBuildingTabGroup('#BuildingTab', '#BuildingNeighborhoodTab')
			._initializeUnitsAndAmenitiesTabs();
	}

	_initializeDynamicTabsResidential() {
		this.buildingTabGroup = initializeBottomDrawerTabComplex('#ResidentialBuildingTabMenu', [
			{ button: '#ResBuildingTab', toggle: '#ContentStripAndFloorPlan, #BuildingSection, .buildingTabSections, #ResidentialBuildingInfo, #BuildingPhoto' },
			{ button: '#ResBuildingNeighborhoodTab', toggle: '#BuildingSection, .buildingGroupsSection, #BottomDrawerMapHolder, #UDGItemsSection, .neighborhoodsSection' }
		], 0);
		return this;
	}

	/**
	 * Initialize the floor plan widget.
	 * @private
	 * @return {BottomDrawerView}
	 */
	_initializeFloorplanViewer() {
		if (!this.floorplanViewer) {
			this.floorplanViewer = EveryScape.SyncV2.FloorPlanViewer({ container: this._$floorPlanContainer.get(0) });
			this.floorplanViewer.events.on('ContentSelected', (contentPath) => { return this.onContentSelected(contentPath); });
		}
		return this;
	}

	_addBuildingMapMarker(building) {
		window.bottomDrawerMapsViewer.addMarker(building.Name, '', building.location.calculated,
			() => { this._handleBuildingSelected(building); }, true, true);
		return this;
	}

	addBuilingMapMarkers() {
		if (window.bottomDrawerMapsViewer) {
			this.contentModel.buildings.forEach((building) => {
				if (building.location) {
					this._addBuildingMapMarker(building);
				} else {
					building._onGeocoded = () => { this._addBuildingMapMarker(building); };
				}
			});
		}
		return this;
	}

	centerMapOnBuilding(building) {
		const gmaps = window.bottomDrawerMapsViewer;
		if (gmaps) {
			if (building.location) {
				gmaps.centerOnPosition(building.location.calculated);
			} else {
				building._onGeocoded = () =>
					gmaps.centerOnPosition(building.location.calculated);
			}
		}

		return this;
	}

	/**
	 * Initialize the google maps widget _if_ google maps functionality is available.
	 * @private
	 * @return {BottomDrawerView}
	 */
	_initializeGoogleMapsViewer() {
		const me = this;

		if (!window.bottomDrawerMapsViewer && ('undefined' !== typeof (google)) && google.maps && google.maps.Map) {
			const domNode = document.getElementById('OverviewMapContainer');
			const root = createRoot(domNode);
			root.render(<MapSearch onMapDblClick={() => me.minimizeBottomDrawer()} />);
		}
		return this;
	}

	/**
	 * Hookup the "next" and "previous" buttons to delegate click events to the controller.
	 * @private
	 * @return {BottomDrawerView}
	 */
	_initializeNextPrevButtons() {
		this._$nextInfoButton.unbind(CLICK).click((evt) => { return this._onNextButtonClick(evt); });
		this._$prevInfoButton.unbind(CLICK).click((evt) => { return this._onPrevButtonClick(evt); });
		return this;
	}

	/**
	 * Initialize all the tabbed areas.
	 * @private
	 * @return {BottomDrawerView}
	 */
	_initializeTabs() {
		this.contentStrip = new BottomDrawerTab(
			{
				button: '#FeaturesTab',
				content: this._$contentStripSection,
				enabled: () => !!(this._$contentStripSection.has(DOT_ITEMCELL).length),
				precedence: 1
			},
			{
				button: '#DetailsTab',
				content: this._$detailsSection,
				enabled: () => false
			},
			{
				button: '#FloorPlanTab',
				content: '#FloorPlanSection',
				enabled: () => true,
				onSelected: () => { this.floorplanViewer.events.emit('Shown');  }
			},
			{
				button: '#SpaceInfoTab',
				content: this._$spaceInfoSection,
				enabled: () => {
					return this._$spaceInfoSection.find(DOT_SPACEFEATURES).filter((_i, el) => {
						return $(el).data(DATA_LISTINGID) === this.contentModel.selectedListingId;
					}).length > 0;
				}
			}
		);
		return this;
	}

	_initializeUnitsAndAmenitiesTabs() {
		this.spaceTabGroup = initializeBottomDrawerTabComplex('#MultifamilyBuildingMenu', [
			{
				button: '#BuildingFloorPlansTab',
				toggle: (on, isClick) => {
					if (on) {
						if (isClick) { this.listMode = true; }
						this._updateBuildingListModeElements();
						$('.amenityContainer').hide();
						$('.buildingAvailabilities').show();
					}
				}
			},
			{
				button: '#BuildingAmenitiesTab',
				toggle: (on, isClick) => {
					if (on) {
						if (isClick) { this.listMode = true; }
						this._updateBuildingListModeElements();
						$('.buildingAvailabilities').hide();
						$('.amenityContainer').show();
					}
				}
			}
		], 0);
		return this;
	}

	_leaseNow(unit, moveInDate) {
		const args = {
			UnitId: unit.SourceUnitId,
			MoveInDate: moveInDate,
			MoveInDateIso: new Date(moveInDate)?.toISOString()?.slice(0, 10),
			SiteId: unit.propertyUnit.property.SourcePropertyId
		};
		const url = unit.LeaseUrlTemplate ? stringInject(unit.LeaseUrlTemplate, args) :
			`https://${unit.propertyUnit.property.RealPageSubdomainId}.onlineleasing.realpage.com/?` + $.param(args);
		window.open(url, '_leaseNow');
		return this;
	}

	_matchTabsAndPanelsToSelectedContentItem() {
		const selectedContentItem = this.contentModel.getSelectedContentItem();
		const selectedListing = this.contentModel.getListing(selectedContentItem?.listingId);
		if (!selectedListing) {
			return this;
		}
		const contentItemType = selectedListing?.PropertyUnitType;
		this.listMode = false; // show the listing not the list of groups
		switch (contentItemType) {
			case 'Amenity':
				this.buildingTabGroup.tabs[0].select();
				this.spaceTabGroup && this.spaceTabGroup.tabs[1].select();
				break;
			case 'Marketable':
				this.buildingTabGroup.tabs[0].select();
				this.spaceTabGroup && this.spaceTabGroup.tabs[0].select();
				break;
			default: // UDG
				this.buildingTabGroup.tabs[1].select();
				$('.groupsContainer').hide();
				$('#UDGItemsHolder').show();
				$('.buildingGroupsTab > button').removeClass('selected');
				$(`#GroupTab-${selectedListing.UserDefinedGroupId}`).addClass('selected');
				break;
		}
		return this;
	}

	/**
	 * Delegate content item selected (button clicks) to controller.
	 * @param {{contentPath: string, position: number[], contentReferenceId: number, contentReferenceType: string}} contentItem
	 * @return {boolean} wasHandled
	 */
	_onContentItemSelected(contentItem) {
		if (this.onContentItemSelected) { return this.onContentItemSelected(contentItem); }
		return false;
	}

	/**
	 * Delegate next button click event to controller.
	 * @param {Event} evt
	 * @return {boolean} wasHandled
	 */
	_onNextButtonClick(evt) {
		if (this.onNextButtonClick) { return this.onNextButtonClick(evt); }
		return false;
	}

	_onPrevButtonClick(evt) {
		if (this.onPrevButtonClick) { return this.onPrevButtonClick(evt); }
		return false;
	}

	_onOpenDatePicker(unit) {
		if (unit?.propertyUnit?.urlOverride) {
			window.open(unit.propertyUnit.urlOverride, '_leaseNow');
			return false;
		}
		const now = new Date();
		let available = new Date(unit.Available);
		if (available < now) {
			available = now;
		}
		const availableMinusOne = new Date();
		availableMinusOne.setDate(available.getDate() - 1);
		const viewer = this;
		$('#ifp-picker').pickadate({
			closeOnSelect: false,
			labelMonthNext: 'Go to the next month',
			labelMonthPrev: 'Go to the previous month',
			labelMonthSelect: 'Pick a month from the dropdown',
			labelYearSelect: 'Pick a year from the dropdown',
			min: [now.getFullYear(), now.getMonth(), 1],
			disable: [{ from: [now.getFullYear(), now.getMonth(), 1], to: [availableMinusOne.getFullYear(), availableMinusOne.getMonth(), availableMinusOne.getDate()] }],
			onRender: function () {
				const currentPicker = this;
				const $leaseNowButton = $('<div>').addClass('ifp-lease-now-button').text('Lease Now');
				const $closeButton = $('<div>').addClass('ifp-close-button').text('X').click(() => { currentPicker.stop(); });
				this.$holder.find('.picker__footer').empty();
				this.$holder.find('.ifp-lease-now-button,.ifp-picker-heading').remove();
				this.$holder.find('.picker__frame').append($leaseNowButton).prepend($closeButton);
				this.$holder.find('.picker__box').prepend($('<div class="ifp-picker-heading">').text('Move In Date'));

				$leaseNowButton.unbind('click').click(function () {
					const value = currentPicker.get('select', 'mm/dd/yyyy');
					if (value) {
						currentPicker.stop();
						viewer._$availabilityDialog.dialog('close');
						viewer._leaseNow(unit, value);
					}
				});
			},
			onClose: function () {
				this.stop();
			}
		});
		$('#ifp-picker').pickadate('picker').open().set('select', `${available.getMonth() + 1}/${available.getDate()}/${available.getFullYear()}`).open();
		return false;
	}

	_renderBottomDrawer() {
		return this._renderContentStripAndFloorPlan()
			._renderBuildingSection()
			._renderBuildingSelectorBtn()
			._updateMapBasis()
			._renderProjectDependantUI(this.contentModel.content.ProjectTypeName);
	}

	_renderBuildingAmenities($container, building) {
		const $div = $('<div>').addClass('placesGrid');
		this._getSortedPropertyUnitList(building, 'amenity', 'Amenities').forEach((pu) => {
			const listing = pu.Listings[0];
			const $pictureLink = this._buildPictureLink(listing);
			$pictureLink.unbind(CLICK).click(() => {
				this.listMode = false;
				this.buildingTabGroup && this.buildingTabGroup.rerender();
				this.onListingSelected(listing);
				return false;
			});
			$div.append($pictureLink);
		});
		$container.append($('<div>').addClass('amenityContainer')
			.append($('<div>').addClass('buildingAmenities infinityyGroup viewerSection buildingContent')
				.append($('<div>').addClass('buildingsSectionTitleBar titleTextBold oneLineText groupTitle').text('Amenities'))
				.append($div)));
		return this;
	}

	_formatResidentialBuildingInfo(building) {
		this.price = this.beds = this.baths = this.sf = '';
		//spread the marketable map into an array, get the first entry, then get the value of the key/item pair
		if (!building?.UnitTypes?.marketable?.size) { return this; }
		const firstMarketable = [...building.UnitTypes.marketable.values()][0];
		if (!firstMarketable?.Listings?.length) { return this; }
		const firstListing = firstMarketable.Listings[0];
		//eventually we may want to support multiple units in a res building
		//when that happens, we will need a more nuanced approach than just grabbuing the first listing (and also UI to support it)
		this.price = "$" + addCommasToNumber(firstListing.Price, false, false);
		this.beds = parseInt(firstListing.Bedrooms, 10) == 1 ? firstListing.Bedrooms + " Bed" : firstListing.Bedrooms + " Beds";
		this.baths = parseInt(firstListing.Bathrooms, 10) == 1 ? firstListing.Bathrooms + " Bath" : firstListing.Bathrooms + " Baths";
		this.sf = addCommasToNumber(firstListing.SquareFeet, true, false);
		return this;
	}

	_getSortedPropertyUnitList(building, unitType, listingUnitType) {
		const map = building.UnitTypes[unitType] || new Map();
		const unsortedList = Array.from(map.values());
		const sortOrder = this.contentModel.displayOrder.Properties.find((b) => { return building.Id === b.Id; });
		if (sortOrder && sortOrder.Listings && sortOrder.Listings[listingUnitType]) {
			const firstOnes = [];
			sortOrder.Listings[listingUnitType].forEach((puid) => {
				const i = unsortedList.findIndex((e) => (e.Listings && e.Listings.length > 0) && e.Listings[0].Id === puid);
				if (i >= 0) {
					firstOnes.push(unsortedList.splice(i, 1)[0]);
				}
			});
			return firstOnes.concat(unsortedList);
		}
		return unsortedList;
	}

	_renderBuildingFloorPlans($container, building) {
		const $listingDiv = $('<div>').addClass('mobileListingList');
		this._getSortedPropertyUnitList(building, 'marketable', 'Marketable').forEach((pu) => {
			if (pu && pu.Listings && pu.Listings.length > 0) {
				const $listing = $(buildMobileListing(pu.Listings[0], this.contentModel.content.ProjectTypeName));
				$listing.unbind(CLICK).click(() => {
					this.listMode = false;
					this.buildingTabGroup && this.buildingTabGroup.rerender();
					this.onListingSelected(pu.Listings[0]);
					return false;
				});
				$listingDiv.append($listing);
			}
		});
		$container.append($listingDiv);
		return this;
	}

	_renderBuildingNameAndPhoto() {
		const building = this.contentModel.getSelectedBuilding();
		const address = formatBuildingAddress(building);
		const info = this._formatResidentialBuildingInfo(building);

		$('#ResPrice').text(building ? info.price : '');
		$('#ResBeds').text(building ? info.beds : '');
		$('#ResBaths').text(building ? info.baths : '');
		$('#ResSF').text(building ? info.sf : '');

		$('#MultifamilyBuildingName, #ResAddressLineOne').text(building ? address.firstLine : '');
		$('#MultifamilyBuildingAddress, #ResAddressLineTwo').text(building ? address.secondLine : '');
		$('#BuildingPhoto').css('backgroundImage', building ? `url(${building.CoverPhotoUrl})` : '');
		const logo = this.contentModel.content && this.contentModel.content.Brand ? this.contentModel.content.Brand.LogoUrl : null;
		$('#BuildingLogo, #ResLogo').css('backgroundImage', logo ? `url(${logo})` : '');
		return this;
	}

	_renderBuildingNeighborhoods($container, building) {
		let $buildingGroupsTabs = $('<div>').addClass('buildingGroupsTabs twoItemFlex');
		$container.append($buildingGroupsTabs);
		let $groupsContainer = $('<div>').addClass('groupsContainer');
		$container.append($groupsContainer);
		let showGroup = function (container, group, tabContainer, tab) {
			container.find(".infinityyGroup").hide();
			group.show();
			tabContainer.find(".bottomDrawerTab").removeClass("selected");
			tab.addClass("selected");
			//change from list to grid
			$groupsContainer.show();
			$("#UDGItemsHolder").hide();
		};

		(building.UserDefinedGroups || []).forEach((udg) => {
			if (udg.UserDefinedGroup && udg.UserDefinedGroup.ListingUserDefinedGroups) {
				if (!udg.UserDefinedGroup.ListingUserDefinedGroups?.length) {
					return;
				}

				const $div = $('<div>').addClass('placesGrid');
				const label = udg.UserDefinedGroup.Label;
				udg.UserDefinedGroup.ListingUserDefinedGroups.forEach((ludg) => {
					const listing = ludg.Listing;
					const $pictureLink = this._buildPictureLink(listing);
					$pictureLink.unbind(CLICK).click(() => {
						$groupsContainer.hide();
						$("#UDGItemsHolder").show();
						this.onListingSelected(listing);
						return false;
					});
					$div.append($pictureLink);
				});
				//create a tab for each group
				let $tab = $('<button>').addClass('bottomDrawerTab brandMenuColors');
				$tab.text(label);
				$tab.attr("id", "GroupTab-" + udg.Id);
				$buildingGroupsTabs.append($tab);

				$tab.click(function (e) {
					showGroup($groupsContainer, $group, $buildingGroupsTabs, $tab);
				});

				let $group = $('<div>').addClass('buildingNeighborhoods infinityyGroup viewerSection buildingContent');
				$groupsContainer.append($group
					.append($('<div>').addClass('buildingsSectionTitleBar titleTextBold oneLineText groupTitle').text(label))
						.append($div)
				);
			}
		});
		//initialize container UI by clicking first tab
		$($buildingGroupsTabs.find(".bottomDrawerTab")[0]).click();
		return this;
	}

	_renderBuildingSection() {
		this._renderBuildingSelector()._renderBuildingNameAndPhoto()._renderBuildingSections();
		$('#BuildingFloorPlansTab').click();
		return this;
	}

	_renderBuildingSections() {
		const $holder = $('#SurveyListingsHolder');
		$holder.empty();
		this.contentModel.buildings.forEach((building) => {
			const $floorPlans = $('<div>').addClass('buildingAvailabilities buildingSubleases buildingContent');
			const $amenities = $('<div>').addClass('amenitiesAndUDGs');
			const $neighborhoods = $('<div>').addClass('neighborhoodsSection');
			const $infoSection = buildBuildingInfoSection(building);
			this._renderBuildingFloorPlans($floorPlans, building)
				._renderBuildingAmenities($amenities, building)
				._renderBuildingNeighborhoods($neighborhoods, building);
			const $buildingTabSections = $('<div>').addClass('buildingTabSections')
				.append($floorPlans)
				.append($amenities);
			const $buildingCell = $('<div>').addClass('buildingCell').data('buildingId', building.Id)
				.append($buildingTabSections);
			$buildingCell.append($neighborhoods);
			if ($infoSection) { $buildingCell.append($infoSection); }
			$holder.append($buildingCell);
		});
		return this;
	}

	_handleBuildingSelected(building) {
		$('#BuildingSelector').hide();
		$('#InfoSection').show();
		this.onBuildingSelected(building);
		return false;
	}

	_renderBuildingSelector() {
		const me = this;
		const $buildingSelector = $('#BuildingSelector');
		$buildingSelector.children('.buildingCard').remove();
		this.contentModel.buildings.forEach((building) => {
			const address = formatBuildingAddress(building);
			const $card = $($('#BuildingCard')[0].innerHTML).clone();
			$card.data('buildingId', building.Id);
			$card.css('backgroundImage', `url(${building.CoverPhotoUrl})`);
			$card.find('.addressLineOne').text(address.firstLine);
			$card.find('.addressLineTwo').text(address.secondLine);
			$card.off(CLICK).on(CLICK, () => { this._handleBuildingSelected(building); });
			$buildingSelector.append($card);
		});

		return this;
	}

	_renderBuildingSelectorBtn() {
		/**
		* show building selector btn if num of buildings is > 1
		*/
		this._$buildingMenuBtn.toggle(this.contentModel.buildings.size > 1);
		return this;
	}

	_renderContentStripAndFloorPlan() {
		if (!this.contentModel.getSelectedListing().PropertyUnitType) {
			//is UDG
			return this._renderUDGFeaturesList();
		} else {
			//not UDG
			return this._renderFeaturesList()
				._renderSpaceInfoSection();
		}
	}

	//this is for hiding/showing dynamically created UI elements based on project type
	_renderDynamicProjectDependantUI(projectType) {
		switch (projectType) {
			case "Residential Sales":
				$(".buildingAvailabilities").hide();
				$(".amenityContainer").hide();
				return this;
			case "Multi Family":
				//multifamily is default
			default:
				return this;
		}
	}

	_renderFeaturesList() {
		this._$contentItemsList.empty();
		this.contentModel.selectedListingContentItems.forEach((el) => {
			this._$contentItemsList.append(makeListCell(el, (contentItem) => { return this._onContentItemSelected(contentItem); }));
		});
		return this;
	}

	_renderUDGFeaturesList() {
		this._$UDGItemsList.empty();
		this.contentModel.selectedListingContentItems.forEach((el) => {
			this._$UDGItemsList.append(makeListCell(el, (contentItem) => { return this._onContentItemSelected(contentItem); }));
		});
		return this;
	}

	_renderProjectDependantUI(projectType) {
		let residentialElements = {
			only: [
				this._$residentialBuildingInfo,
				this._$residentialBuildingTabMenu
			],
			never:[
				this._$spaceNameAndInfo,
				this._$multifamilyBuildingInfo,
				this._$multifamilyBuildingMenu
				]
		};
		let multifamilyElements = {
			only: [
				this._$multifamilyBuildingTabMenu
			],
			never: []
		};
		let marketingCenterElements = {
			only: [
				this._$marketingCenterBuildingTabMenu,
				this._$additionalMCInfo
			],
			never: []
		};
		let els = {
			residential: residentialElements,
			marketingCenter: marketingCenterElements,
			multifamily: multifamilyElements
		};
		let elKey;
		switch (projectType) {
			case "Residential Sales":
				elKey = "residential";
				break;
			case "Marketing Campaign":
				elKey = "marketingCenter";
				break;
			case "Multi Family":
			default:
				elKey = "multifamily";
		}

		let hideOrShowElements = function (anArray, hideOrShow) {
			switch (hideOrShow) {
				case "hide":
					for (let el of anArray) {
						el.hide();
					}
					break;
				case "show":
				default:
					//default is show
					for (let el of anArray) {
						el.show();
					}
			}
		};

		let updateElements = function (elType) {
			for (let i in els) {
				if (els[i] === els[elType]) {
					hideOrShowElements(els[i].only, "show");
					hideOrShowElements(els[i].never, "hide");
				} else {
					hideOrShowElements(els[i].only, "hide");
				}
			}
		};

		updateElements(elKey);
		return this;
	}

	_renderSpaceInfoSection() {
		return this._renderSpaceNameAndBasicInfo()._renderSpaceInfoTabContent();
	}

	/**
	 * Space info tab is rendered to HTML on building update and then sections are toggled on/off by listing.
	 */
	_renderSpaceInfoTabContent() {
		const uniqueListingIds = new Set(); // avoid duplicates.
		this.contentModel.content.Properties.forEach((b) => {
			const building = this.contentModel.buildings.get(b.Id);
			this._$spaceInfoSection.append(buildFeatures(building, uniqueListingIds));
		});
		return this;
	}

	_renderSpaceNameAndBasicInfo() {
		this._$spaceNameAndInfo.empty();
		const listing = this.contentModel.getSelectedListing();
		if (!listing) { return this; }
		const newCard = buildMobileListing(listing, this.contentModel.content.ProjectTypeName);
		this._$spaceNameAndInfo.append(newCard);
		this._$listingPhoto.css('backgroundImage', listing.ThumbnailUrl ? `url(${listing.ThumbnailUrl})` : '');
		return this;
	}

	_setContentBrandColors() {
		const brand = this.contentModel.content.Brand;
		if (brand && brand.StylesJson) {
			const styles = JSON.parse(brand.StylesJson);
			if (styles && styles.UseCustomStyles) {
				// [[style json property name, style css name (without --brand prefix)]]
				[
					['Button', 'ViewerButton'], ['ButtonText', 'ViewerButtonText'], ['Hover', 'ViewerButtonHover'], ['HoverText', 'ViewerButtonHoverText'],
					['ToggleOff', 'HoverOff'], ['ToggleOffText', 'HoverOffText'],
					['Cta', 'CTAButton'], ['CtaText', 'CTAButtonText'], ['CtaHover', 'CTAButtonHover'], ['CtaHoverText', 'CTAButtonHoverText'],
					['Header', 'HeaderColor'], ['HeaderTextPrimary', 'HeaderPrimaryText'], ['HeaderTextSecondary', 'HeaderSecondaryText'],
					['Menu', 'MenuColor'], ['MenuTextPrimary', 'MenuPrimaryText'], ['MenuTextSecondary', 'MenuSecondaryText'],
					['Content', 'ContentColor'], ['ContentPrimaryText', 'ContentPrimaryText'], ['ContentSecondaryText', 'ContentSecondaryText']
				].forEach((bc) => {
					if (styles[bc[0]]) {
						document.body.style.setProperty('--brand' + bc[1], styles[bc[0]]);
					}
				});
			}
		}
		return this;
	}

	_updateBottomDrawer() {
		return this._updateTopInfoSection()
			._updateContentStripAndFloorPlan()
			._updateBuildingSection();
	}

	_updateBuildingListModeElements() {
		$('#ContentStripAndFloorPlan, #ListingPhoto, #AdditionalMCInfo').toggle(!this.listMode);
		$('.buildingTabSections, #BuildingPhoto').toggle(this.listMode);
		return this;
	}

	_updateBuildingSection() {
		const selectedBuilding = this.contentModel.getSelectedBuilding();
		const selectedBuildingId = selectedBuilding ? selectedBuilding.Id : null;
		$('#SurveyListingsHolder').find('.buildingCell').each((_, el) => {
			const $el = $(el);
			$el.toggle($el.data('buildingId') === selectedBuildingId);
		});
		return this;
	}

	_updateContentStripAndFloorPlan() {
		return this._updateFeaturesList()
			._updateSpaceInfoSection()
			._updateContentStripAndFloorPlanTabs();
	}

	_updateContentStripAndFloorPlanTabs() {
		this.contentStrip.update();
		return this;
	}

	_updateFeaturesList() {
		this._$contentItemsList.children().removeClass(CURRENT_ITEM);
		this._$UDGItemsList.children().removeClass(CURRENT_ITEM);
		let list;
		if (!this.contentModel.getSelectedListing().PropertyUnitType) {
			//UDG
			list = this._$UDGItemsList;
		} else {
			//NON UDG
			list = this._$contentItemsList;
		}
		list.children().eq(this.contentModel.selectedListingSelectedContentItemIndex).addClass(CURRENT_ITEM);
		return this;
	}

	_updateMapBasis() {
		const gmaps = window.bottomDrawerMapsViewer;
		if (gmaps) {
			const building = this.contentModel?.getSelectedBuilding();
			if (building && building.location) {
				gmaps.centerOnPosition(building.location.calculated);
				gmaps.setTrackingPoint(building.location.calculated);
			}
		}
		return this;
	}

	/**
	 * Update the info tab based on the currently selected listing.
	 * @return {BottomDrawerView}
	 */
	_updateSpaceInfoForSelectedListing() {
		this._$spaceInfoSection.children(DOT_SPACEFEATURES)
			.each((_i, el) => {
				const $el = $(el);
				$el.toggle(($(el).data(DATA_LISTINGID) === this.contentModel.selectedListingId));
			});
		return this;
	}

	_updateSpaceInfoSection() {
		return this._updateSpaceInfoForSelectedListing();
	}

	_updateTopInfoSection() {
		const index = this.contentModel.selectedContentItemIndex;
		const contentItem = this.contentModel.contentItems[index];
		const building = this.contentModel.getSelectedBuilding();
		const listing = this.contentModel.getSelectedListing();

		this._$nextPreviousButtons.toggle(this.contentModel.contentItems.length > 1);
		const description = contentItem?.description || contentItem.name;
		this._$contentDescription.text(description);

		const listingFormatted = getListingFormattedAttributes(listing, this.contentModel.content?.ProjectTypeName);
		let contextLine = listingFormatted.name || listing?.AddressStreet1;
		let attributeLine = listing?.Description || (listing?.AddressCity && listing?.AddressState) ? [listing?.AddressCity, listing?.AddressState].join(', ') : '';
		switch (listing.PropertyUnitType?.toLowerCase()) {
			case UNIT_TYPES.MARKETABLE.toLowerCase():
				contextLine = listingFormatted.name || listing?.AddressStreet1;
				attributeLine = this._formatListingInfoAttributeLine(listingFormatted);
				break;
			case UNIT_TYPES.AMENITY.toLowerCase():
				contextLine = building?.Name || building?.Address1;
				break;
		}
		this._$topInfoContext.text(contextLine);
		this._$topInfoAttributes.html(attributeLine ?? '');

		const title = contentItem ? contentItem.name : '';
		this._$contentTitle.text(title);
		this._$contentTitleDrawer.text(title);
		this._$topInfoTitle.text(title);

		this.onRequestTts(TTS_SOURCES.MOVEMENT, description);

		return this;
	}

	_formatListingInfoAttributeLine(listingFormatted) {
		if (IrUtils.isNully(listingFormatted)) {
			return null;
		}

		const sequence = new DynamicDataSequence();
		const bb = [];
		const priceRange = [];

		if (!isNullyOrEmptyString(listingFormatted.beds)) {
			const bed = Math.floor(+listingFormatted.beds);
			if (bed === 0) {
				bb.push('Studio');
			} else {
				bb.push(`${bed} bed`);
			}
		}
		if (!isNullyOrEmptyString(listingFormatted.baths)) {
			bb.push(`${listingFormatted.baths} bath`);
		}

		if (bb.length) {
			sequence.elements.push(new DynamicDataElement(bb.join(', '), 1, 1));
		}

		if (listingFormatted.price) {
			sequence.elements.push(new DynamicDataElement(listingFormatted.price, 2, 3));
		}

		if (listingFormatted.available) {
			sequence.elements.push(new DynamicDataElement(listingFormatted.available, 3, 4));
		}

		if (!isNullyOrEmptyString(listingFormatted.squareFeet)) {
			sequence.elements.push(new DynamicDataElement(listingFormatted.squareFeet, 4, 2));
		}

		const start = '<span class="m-1">';
		const end = '</span>';
		const between = end + start;

		return `${start}${sequence.topX(3, true).join(between)}${end}`;
	}

	handleTtsAiResponseIntro(html) {
		let text = html || '';
    const iPos = text.indexOf('<i class="lci"');

		if (iPos > 0) {
			text = text.substring(0, iPos);
			
			let introSeparatorIndex = Math.max(
        text.lastIndexOf('<br><br>'),
        text.lastIndexOf('<br/><br/>'),
        text.lastIndexOf('<br> <br>'),
        text.lastIndexOf('<br/> <br/>')
    	);
			
			if (introSeparatorIndex > 0) {
				text = text.substring(0, introSeparatorIndex);
			}
		}

		text = new DOMParser().parseFromString(text, 'text/html').body.textContent;

		this.onRequestTts(TTS_SOURCES.AI_INTRO, text);
		return this;
	}
}

export { BottomDrawerView }
