

class AiApi {
	constructor() {
		this._apiUrl = `${window.siteSettings.infinityy.aiService.url}api/v0`;
	}

	async _sendRequest(method, params) {
		try {
			const response = await fetch(`${window.siteSettings.infinityy.aiService.url}api/v0`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					jsonrpc: '2.0',
					method: method,
					id: Math.random(),
					params: params
				})
			});

			return await response.json();
		} catch (error) {
			console.error('Ai request error:', error);
		}
	}

	async sendMessage(message) {
		const contentModel = window.mobileWebController?.contentModel;
		const params = {
			message,
			projectGuid: contentModel?.project?.PublicId,
			room: { id: window.roomId },
			user: {
				identityId: window.sessionModel?._identity?.Id,
			},
			view: {
				featureId: contentModel?.getSelectedContentItem()?.contentReferenceId,
				listingId: contentModel?.getSelectedListing()?.Id,
				propertyId: contentModel?.getSelectedListing()?.PropertyId,
			}
		};

		return await this._sendRequest('send-message', params);
	}

	async getHistory() {
		const params = {
			roomId: window.roomId,
			identityId: window.sessionModel?._identity?.Id
		};

		const data = await this._sendRequest('get-chat', params);
		return data.result.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
	}

	async recordProblem(messageId, problem) {
		const params = {
			messageId,
			problem
		};
		return await this._sendRequest('record-problem', params);
	}
}

export { AiApi }
