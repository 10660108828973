import { PipWhereMediaDescriptor } from "./pip-where-media-descriptor.es6"
import { getStreetViewStaticMedia } from "../utils/map-utils.es6"
import "./legacy-pip"
//import { pip_where_fragShader, pip_where_vertShader } from "./pip-where-shaders.es6"
//import { Easing, AnimTween } from "./pip-where-animation.es6"

class PipWhereController {
	constructor() {
		const me = this;
		this._disposed = false;
		this._enabled = window.useWhereMode;
		this._renderer = EveryScape.Pip();
		this._renderer.initialize("InnerPipsContainer")
		this._renderer.setLayoutMode('singlerow');
		this._mediaDescriptorIndex = new Map();
		this._refreshIntervalMs = 500; // approximate best effort of 2fps
		this._expireMediaIntervalMs = 90000; // any media that hasn't been referenced in 90s can be flushed
		this._cleanupInterval = setInterval(function () {
			me._clearExpiredMedia(me._mediaDescriptorIndex);
		}, this._expireMediaIntervalMs);

		setTimeout(function () {
			me._doRender(me);
		}, 0);
	}

	_clearExpiredMedia(index) {
		const now = Date.now();
		index.forEach((value, key, map) => {
			if (value.expires <= now) {
				map.delete(key);
			}
		});
	}

	_doRender(me) {
		if (me._enabled) {
			window.requestAnimationFrame(function () {
				me._renderer.render();
				setTimeout(function () { me._doRender(me) }, me._refreshIntervalMs);
			});
		}
	}

	// Retrieve appropriate media descriptors based on the content

	async _fetchEveryScapeMediaDescriptor(content) {
		// This is an asynchronous method because it needs to make an API call to retrieve tile data
		// TODO
		return await this._fetchNoMediaDescriptor;
	}

	_fetchMatterportMediaDescriptor(content) {
		const mediaDescriptor = new PipWhereMediaDescriptor();
		mediaDescriptor.isSphere = true;
		const baseUrl = window.siteSettings.OlioMediaBaseUrl;
		const modelId = content.pathMap.get('model').toLowerCase(); // Note: lowercase is a requirement of the azure storage, but it does allow for collisions since model ids are case sensitive.
		const sweepId = content.pathMap.get('sweep');
		if (modelId && sweepId && baseUrl) {
			mediaDescriptor.mediaIndex = content.contentPath;
			mediaDescriptor.mediaPath = `${baseUrl}/mpmodel-${modelId}/sweep-${sweepId}`
		}
		return mediaDescriptor;
	}

	_fetchNoMediaDescriptor(content) {
		const mediaDescriptor = new PipWhereMediaDescriptor();
		mediaDescriptor.isSphere = false;
		mediaDescriptor.mediaIndex = content.contentPath;
		const baseUrl = window.siteSettings.infinityy.api.url;
		mediaDescriptor.mediaPath = `${baseUrl}/Content/Images/empty_pip.png`;
		return mediaDescriptor;
	}

	_fetchOlioMediaDescriptor(content) {
		const mediaDescriptor = new PipWhereMediaDescriptor();
		mediaDescriptor.isSphere = false;
		const baseUrl = window.siteSettings.OlioMediaBaseUrl;
		const container = content.pathMap.get('container');
		const file = content.pathMap.get('file');
		if (container && file && baseUrl) {
			mediaDescriptor.mediaIndex = content.contentPath;
			mediaDescriptor.mediaPath = `${baseUrl}/${container}/${file}_thumb.jpg`;
		}
		return mediaDescriptor;
	}

	_fetchOverlayMediaDescriptor(content) {
		const mediaDescriptor = new PipWhereMediaDescriptor();
		mediaDescriptor.isSphere = false;
		mediaDescriptor.mediaIndex = content.contentPath;
		const baseUrl = window.siteSettings.infinityy.api.url;
		mediaDescriptor.mediaPath = `${baseUrl}/Content/Images/no_location.png`;
		return mediaDescriptor;
	}

	_fetchStreetviewMediaDescriptor(content, mediaIndex, roundedHeading) {
		const mediaDescriptor = new PipWhereMediaDescriptor();
		mediaDescriptor.isSphere = false;
		mediaDescriptor.mediaIndex = mediaIndex;
		mediaDescriptor.mediaPath = getStreetViewStaticMedia(content.pathMap.get('panorama'), roundedHeading);

		return mediaDescriptor;
	}

	_fetchVideoMediaDescriptor(content) {
		const mediaDescriptor = new PipWhereMediaDescriptor();
		mediaDescriptor.isSphere = false;
		mediaDescriptor.mediaIndex = content.contentPath;
		const baseUrl = window.siteSettings.OlioMediaBaseUrl;
		const container = content.pathMap.get('container');
		if (container && baseUrl) {
			mediaDescriptor.mediaPath = [baseUrl, container, 'poster.jpg'].join('/');
		}
		return mediaDescriptor;
	}

	async _fetchMediaDescriptor(content, position) {
		// This is an async method because the EveryScape content has to be
		let roundedHeading = null;
		let mediaIndex = '';
		if (content.contentType === 'streetview') {
			roundedHeading = Math.round(((position[0] + 360) % 360 / 45)) * 45;
			mediaIndex = `${content.contentPath}@${roundedHeading}`;
		} else {
			mediaIndex = content.contentPath ?? 'unknown';
		}
		
		let mediaDescriptor = this._mediaDescriptorIndex.get(mediaIndex);
		if (!mediaDescriptor) {
			switch (content?.contentType) {
				case 'everyscape':
					mediaDescriptor = await this._fetchEveryScapeMediaDescriptor(content);
					break;
				case 'matterport':
					mediaDescriptor = await this._fetchMatterportMediaDescriptor(content);
					break;
				case 'overlay':
					mediaDescriptor = await this._fetchOverlayMediaDescriptor(content);
					break;
				case 'olio':
					mediaDescriptor = await this._fetchOlioMediaDescriptor(content);
					break;
				case 'pdf':
					mediaDescriptor = await this._fetchOlioMediaDescriptor(content);
					break;
				case 'streetview':
					mediaDescriptor = await this._fetchStreetviewMediaDescriptor(content, mediaIndex, roundedHeading);
					break;
				case 'video':
					mediaDescriptor = await this._fetchVideoMediaDescriptor(content);
					break;
				default:
					mediaDescriptor = await this._fetchNoMediaDescriptor(content);
					break;
			}
			this._mediaDescriptorIndex.set(mediaIndex, mediaDescriptor);
		}
		return mediaDescriptor;
	}

	_parseContentPath = function (contentPath) {
		var separator = '://';
		var separatorPosition = contentPath.indexOf(separator);
		if (separatorPosition > 0) {
			var viewerType = contentPath.substring(0, separatorPosition);
			var pathPortion = contentPath.substring(separatorPosition + separator.length);
			var contentParts = pathPortion.split('/');
			var contentPartsMap = new Map();

			if (contentParts.length % 2 === 0) {
				for (var i = 0; i < contentParts.length; i += 2) {
					if (i % 2 === 0 && contentParts[i] !== '') {
						contentPartsMap.set(contentParts[i], contentParts[i + 1]);
					}
				}
			}
			return {
				contentPath: contentPath,
				contentType: viewerType,
				pathMap: contentPartsMap
			}
		}
		return null;
	}

	dispose() {
		this._disposed = true;
		// TODO: clean up elements in the DOM
		clearInterval(this._cleanupInterval);
	}

	removeUserWhere(clientInstanceId) {
		this._renderer.removeView(clientInstanceId);
	}

	async updateUserWhere(clientInstanceId, contentPath, position, heading) {
		// find the media to use
		const content = this._parseContentPath(contentPath);
		const mediaDescriptor = await this._fetchMediaDescriptor(content, position);
		let espipView = this._renderer.getViewForId(clientInstanceId);
		let animatePan = true;
		if (!espipView || espipView.getMediaIndex() !== mediaDescriptor.mediaIndex) {
			const pipType = mediaDescriptor.isSphere ? 'pip' : 'pip2d';
			espipView = this._renderer.updateView(clientInstanceId, pipType, mediaDescriptor.mediaPath);
			animatePan = false;
		}

		// handle panning
		switch (content.contentType) {
			// Right now, we're showing a static 'no content' image for everyscape, but when we implement
			// looking up the pano tiles, this can be uncommented.
			//case 'everyscape':
			//	/*
			//	 * hlookat = position[0],
			//	 * vlookat = position[1],
			//	 * fov = position[2]
			//	 */
			//	var hlookat = position[0];
			//	var vlookat = position[1];
			//	var fov = position[2];
			//	if (espipView.fov.getValue() !== fov) {
			//		if (animatePan) {
			//			espipView.gotoFov(fov);
			//		} else {
			//			espipView.setFov(fov);
			//		}
			//	}
			//	if (espipView.pitchDegrees.getValue() !== vlookat * -1 || espipView.yawDegrees.getValue() !== hlookat + 90) {
			//		// Offset for horizontal
			//		if (animatePan) {
			//			espipView.gotoYawPitchDegrees(parseFloat(hlookat) + 90, parseFloat(vlookat) * -1);
			//		} else {
			//			espipView.setYawPitchDegrees(parseFloat(hlookat) + 90, parseFloat(vlookat) * -1);
			//		}
			//	}
			//	break;
			case 'matterport':
				/*
				 * yaw = position[4], (already mapped to rational by heading property)
				 * pitch = position[3],
				 * fov ~~ position[5]
				 */
				var destYaw = heading + 90;
				var destPitch = position[3];
				var destFov = 75 / position[5];

				if (espipView.fov.getValue() !== destFov) {
					if (animatePan) {
						espipView.gotoFov(destFov);
					} else {
						espipView.setFov(destFov);
					}
				}
				if (espipView.pitchDegrees.getValue() !== destPitch || espipView.yawDegrees.getValue() !== destYaw) {
					// Offset for horizontal
					if (animatePan) {
						espipView.gotoYawPitchDegrees(destYaw, destPitch);
					} else {
						espipView.setYawPitchDegrees(destYaw, destPitch);
					}
				}
				break;
			case 'olio':
				/*
				 * x = position[0],
				 * y = position[1],
				 * zoom = position[2]
				 */
				var x = position[0];
				var y = position[1];
				var zoom = position[2];
				if (espipView.zoomFactor.getValue() !== zoom) {
					if (animatePan) {
						espipView.gotoZoomFactor(zoom);
					} else {
						espipView.setZoomFactor(zoom);
					}
				}
				if (espipView.position2D[0] !== x || espipView.position2D[1] !== y * -1) {
					if (animatePan) {
						espipView.gotoPosition2D(parseFloat(x), parseFloat(y) * -1);
					} else {
						espipView.setPosition2D(parseFloat(x), parseFloat(y) * -1);
					}
				}
				break;
			case 'overlay':
			case 'streetview':
			case 'video':
			default:
				espipView.setPosition2D(parseFloat(0), parseFloat(0) * -1);
				espipView.setZoomFactor(1);
				break;
		}

	}
}

export { PipWhereController }
