const showdown = require('showdown');

class LeadCaptureView {
	constructor(options) {
		this._activityMonitor = options?.activityMonitor;
		this._api = options?.api;
		this._contentModel = options?.contentModel;
		this._leadModel = options?.leadModel;
		this._userModel = options?.userModel;
	}

	/**
	 * If the user isn't logged in and hasn't provided contact info yet, request it from them.
	 * @param {any} callback
	 * @returns
	 */
	getLeadBeforeSendingChat(callback) {
		if (!this._contentModel.isChatLeadCaptureEnabled || this._userModel.isLoggedIn || this._leadModel.isLeadClientValid()) {
			return new Promise(callback);
		} else {
			this._chatCallbackAfterLeadCapture = callback;
			setTimeout(() => {
				// Hack: this was failing to work properly without this.
				this._$chatLeadCaptureDialog.dialog('open');
			}, 0);
		}
		return this;
	}

	initializeView() {
		return this._initializeRequestAccessDialog()
			._initializeChatLeadCaptureDialog();
	}

	setContentLoaded() {
		this._$requestAccessSection.toggle(!this._leadModel.hasRequestedProjectAccess(this._contentModel.projectId) && this._contentModel.isRequestAccessButtonEnabled);
		if (this._contentModel.isRequestAccessButtonEnabled) {
			if (this._contentModel.requestAccessButtonText) {
				$('.request-access-button-text').text(this._contentModel.requestAccessButtonText);
			}
			if (this._contentModel.requestAccessDialogMarkdown) {
				$('#RequestAccessDialogProjectContentInner').html(new showdown.Converter().makeHtml(this._contentModel.requestAccessDialogMarkdown));
			}
		}
		if (this._contentModel.isChatLeadCaptureEnabled && this._contentModel.chatLeadCaptureDialogMarkdown) {
			$('#ChatLeadCaptureDialogContentInner').html(new showdown.Converter().makeHtml(this._contentModel.chatLeadCaptureDialogMarkdown));
		}
		return this;
	}

	_getFormContact($formFields) {
		const result = {};
		$formFields.each((_i, el) => {
			const name = $(el).attr('name');
			const val = $(el).val();
			if (val) {
				result[name] = val;
			}
		});
		return result;
	}

	/**
	 * Get data from the "Chat Lead Capture" form.
	 * @returns {LeadContact}
	 */
	_getChatLeadCaptureFormContact() {
		return this._getFormContact(this._$chatLeadCaptureFormFields);
	}

	/**
	 * Get data from the "Request Access" form.
	 * @returns {LeadContact}
	 */
	_getRequestAccessFormContact() {
		return this._getFormContact(this._$requestAccessFormFields);
	}

	_initializeChatLeadCaptureDialog() {
		const $dialogDiv = $('#ChatLeadCaptureDialog');
		this._$chatLeadCaptureDialogSubmitButton = $dialogDiv.find('button.submit');
		this._$chatLeadCaptureForm = $('.chat-lead-capture-form');
		this._$chatLeadCaptureFormFields = this._$chatLeadCaptureForm.find('input,textarea');

		this._$chatLeadCaptureDialog = $dialogDiv.dialog({
			autoOpen: false, dialogClass: 'chat-lead-capture-dialog',
			modal: true, resizable: false, width: 'auto',
			classes: {
				'ui-dialog': 'chat-lead-capture-dialog'
			}
		});
		$dialogDiv.on('dialogopen', () => {
			this._toggleDisabledChatLeadCaptureDialogSubmitButton(!this._isChatLeadCaptureFormValid());
			$('.chat-lead-capture-dialog').css({ width: 'calc(100% - 20px)', left: '10px' });
		});
		this._$chatLeadCaptureFormFields.on('input', () => {
			this._toggleDisabledChatLeadCaptureDialogSubmitButton(!this._isChatLeadCaptureFormValid());
		});
		$dialogDiv.find('button.close,button.cancel').on('click', () => {
			this._$chatLeadCaptureDialog.dialog('close');
			this._chatCallbackAfterLeadCapture = null;
		});
		this._$chatLeadCaptureDialogSubmitButton.on('click', () => { this._onSubmitChatLeadCaptureForm(); });
		return this;
	}

	_initializeRequestAccessButtonSection() {
		this._$requestAccessSection = $('.request-access-button-section').toggle(this._contentModel.isRequestAccessButtonEnabled);
		this._$requestAccessButton = $('.request-access-button');
		this._$requestAccessButton.on('click', () => {
			if (this._contentModel.isRequestAccessButtonEnabled) {
				this._setRequestAccessFormContact()
					._toggleDisabledRequestAccessDialogSubmitButton(!this._isRequestAccessFormValid());
				this._$requestAccessDialog.dialog('open');
			}
		});
		if (this._contentModel.isRequestAccessButtonEnabled && this._contentModel.requestAccessButtonText) {
			$('.request-access-button-text').text(this._contentModel.requestAccessButtonText);
		}
		return this._toggleDisabledRequestAccessDialogSubmitButton(!this._isRequestAccessFormValid());
	}

	_initializeRequestAccessDialog() {
		const $dialogDiv = $('#RequestAccessDialog');
		this._$requestAccessDialogSubmitButton = $dialogDiv.find('button.submit');
		this._$requestAccessForm = $('.request-access-form');
		this._$requestAccessFormFields = this._$requestAccessForm.find('input,textarea');

		this._$requestAccessDialog = $dialogDiv.dialog({
			autoOpen: false, dialogClass: 'request-access-dialog',
			modal: true, resizable: false, width: 'auto',
			classes: {
				'ui-dialog': 'request-access-dialog'
			}
		});
		$dialogDiv.on('dialogopen', () => {
			$('.request-access-dialog').css({ width: 'calc(100% - 20px)', left: '10px' });
		});
		this._initializeRequestAccessButtonSection();
		this._$requestAccessFormFields.on('input', () => {
			this._toggleDisabledRequestAccessDialogSubmitButton(!this._isRequestAccessFormValid());
		});
		$dialogDiv.find('button.close,button.cancel').on('click', () => {
			this._$requestAccessDialog.dialog('close');
		});
		this._$requestAccessDialogSubmitButton.on('click', () => { this._onSubmitRequestAccessForm(); });
		return this;
	}

	_isFormValid($form) {
		let result = true;
		$form.find('input,textarea').each((_i, el) => {
			const name = $(el).attr('name');
			const validator = this._leadModel.validators[name];
			if (validator && !validator($(el).val())) {
				result = false;
			}
		});
		return result;
	}

	_isChatLeadCaptureFormValid() {
		return this._isFormValid(this._$chatLeadCaptureForm);
	}

	_isRequestAccessFormValid() {
		return this._isFormValid(this._$requestAccessForm);
	}

	_onSubmitChatLeadCaptureForm() {
		this._leadModel.setLeadContact(this._getChatLeadCaptureFormContact());
		this._$chatLeadCaptureDialog.dialog('close');
		if (this._chatCallbackAfterLeadCapture) {
			new Promise(this._chatCallbackAfterLeadCapture);
			this._chatCallbackAfterLeadCapture = null;
		}
		return this;
	}

	_onSubmitRequestAccessForm() {
		this._leadModel.setLeadContact(this._getRequestAccessFormContact());
		this._sendRequestAccessNotification();
		this._$requestAccessSection.hide();
		this._leadModel.setHasRequestedProjectAccess(this._contentModel.projectId);
		this._$requestAccessDialog.dialog('close');
		return this;
	}

	_sendRequestAccessNotification() {
		this._activityMonitor.requestedAccess();
		return this;
	}

	_setFormContact($formFields) {
		const leadContact = this._leadModel.leadContact;
		$formFields.each((_i, el) => {
			const name = $(el).attr('name');
			$(el).val(leadContact[name] || '');
		});
		return this;
	}

	/**
	 * Initialize the "Chat Lead Capture" form from stored leadcontact.
	 * @returns {LeadModel}
	 */
	_setChatLeadCaptureFormContact() {
		return this._setFormContact(this._$chatLeadCaptureFormFields);
	}

	/**
	 * Initialize the "Request Access" form from stored leadcontact.
	 * @returns {LeadModel}
	 */
	_setRequestAccessFormContact() {
		return this._setFormContact(this._$requestAccessFormFields);
	}

	_toggleDisabledChatLeadCaptureDialogSubmitButton(disabled) {
		this._$chatLeadCaptureDialogSubmitButton.attr('disabled', disabled);
		return this;
	}

	_toggleDisabledRequestAccessDialogSubmitButton(disabled) {
		this._$requestAccessDialogSubmitButton.attr('disabled', disabled);
		return this;
	}
}

export { LeadCaptureView }
