import { getInfinityyViewerUrl, getShareLink } from "../utils/redirect.es6.js"
import { getEmailAddressesFromString, getPhoneNumbersFromString, prepEmailContent, sendEmailMessage } from "./invite.es6.js"
import { createLead, getChatInviteInfo, getShortenedUrl, sendSmsMessage } from "../api/api.es6.js"
import { toggleDialog } from "../utils/ui-helpers.es6.js"
import { doToast, TOAST_TYPES } from "../utils/toastr-wrapper.es6.js"
import { onboarding } from '../onboarding/onboarding.mjs';

/**
 * @file View of the header menu and page modals (for now).
 */

const CLICK = 'click';
const CLOSE = 'close';
const DISABLED = 'disabled';
const OPEN = 'open';

/**
 * Rendering and updating header menu and related widgets/modals.
 * @class
 */
class HeaderMenuView {
	constructor(headerMenuModel) {
		/** @member {HeaderMenuModel} */
		this.headerMenuModel = headerMenuModel;

		// Controller hooks.
		this.onChatButtonClicked = function () { return false; };
		this.onForgotPasswordClicked = function () { return false; };
		this.onHamburgerClicked = function () { return false; };
		this.onHeaderInviteClicked = function () { return false; };
		this.onSaveRoomClicked = function () { return false; };
		this.onIdentified = function (_displayName) { return false; }
		this.onLogin = function (_username, _password) { return false; };
		this.onLoginClicked = function () { return false; };
		this.onModalCloseClicked = function () { return false; };
		this.onSignupClicked = function () { return false; };
		this.onIdentifyModalOpened = function () { return false; };
		this.onIdentifyModalSuccess = function () { return false; };
		this.onIdentifyModalSkipped = function () { return false; };
	}

	initializeView() {
		this._cacheDomElements()._initializeEventHandlers();

		[this._$chatButton, this._$hamburgerButton, this._$inviteButton].forEach((btn) => {
			btn.removeClass(DISABLED);
		});

		return this.update();
	}

	update() {
		const model = this.headerMenuModel;

		this._$audioMuteButton.toggleClass(DISABLED, !model.callConnected).toggleClass('disabled', !model.callConnected);
		this._$buildingApplyNowButton.toggleClass('hidden', !this.headerMenuModel.buildingApplyNowUrl);
		this._$buildingBookingButton.toggleClass('hidden', !this.headerMenuModel.buildingBookingUrl);
		this._$contactUsCtaSection.toggleClass('hidden', !this.headerMenuModel.buildingBookingUrl && !this.headerMenuModel.buildingApplyNowUrl);
		this._$callButton.toggleClass(DISABLED, !model.callConnectButtonEnabled);
		this._$videoMuteButton.toggleClass(DISABLED, !model.callConnected).toggleClass('disabled', !model.callConnected);
		this._$hamburgerMenu.toggle(model.hamburgerMenuOpen);
		this._$hamburgerButton.toggleClass('inverseColors', model.hamburgerMenuOpen)
			.find('i').text(model.hamburgerMenuOpen ? 'close' : 'menu');
		this._$chatModal.dialog(model.chatModalOpen ? OPEN : CLOSE);
		this._$loginModal.dialog(model.loginModalOpen ? OPEN : CLOSE);
		this._$inviteModal.dialog((model.inviteModalOpen && !model.showInviteExplanation) ? OPEN : CLOSE);
		this._$inviteExplanationModal.dialog((model.inviteModalOpen && model.showInviteExplanation) ? OPEN : CLOSE);
		this._$saveRoomModal.dialog(model.saveRoomModalOpen ? OPEN : CLOSE);
		this._$contactUsModal.dialog(model.contactUsModalOpen ? OPEN : CLOSE);
		toggleDialog(this._identifyDialog.$dialog, model.identifyModalOpen, {
			onOpen: () => {
				this._identifyDialog.$nameTextbox.val(this.headerMenuModel.identifyDialogDisplayName || '').focus();
				this.onIdentifyModalOpened();
			}
		});
		this._$loadingAnimation.toggle(model.showLoadingAnimation);
		return this;
	}

	_cacheDomElements() {
		this._$audioMuteButton = $('#YouToggleMicTwo');
		this._$buildingApplyNowButton = $('#DialogApplyNowBtn');
		this._$buildingBookingButton = $('#DialogBookATourBtn');
		this._$callButton = $('#MobileCallBtn');
		this._$chatButton = $('#MobileChatButton');
		this._$chatModal = $('#ModalChat').dialog({ autoOpen: false });
		this._$contactUsCtaSection = $('#ContactUsButtons');
		this._$contactUsModal = $('#ModalContactUs').dialog({ autoOpen: false });
		this._$hamburgerButton = $('#MobileMenuBtn');
		this._$hamburgerMenu = $('#MobileMenuHolder');
		this._identifyDialog = {
			$dialog: $('#ModalSelfIdentify').dialog({ autoOpen: false }),
			$doneButton: $('#ModalSelfIdentifyCreate'),
			$skipButton: $('#ModalSelfIdentifySkip'),
			$loginButton: $('#ModalSelfIdentifyLogin'),
			$nameTextbox: $('#SelfUsername')
		};
		this._$inviteButton = $('#MobileInviteButton');
		this._$inviteExplanationModal = $('#ModalExplanation').dialog({ autoOpen: false });
		this._$inviteModal = $('#InviteShowingUsersModal').dialog({ autoOpen: false });
		this._$saveRoomModal = $('#SaveRoom').dialog({ autoOpen: false });
		this._$saveRoomButton = $('#SaveRoomMobile');
		this._$loadingAnimation = $('#LoadingAnimation');
		this._$loginButton = $('#MobileLogIn');
		this._$loginModal = $('#ModalLogin').dialog({ autoOpen: false });
		this._$loginModalButton = $('#ModalLoginLoginBtn');
		this._$loginModalForgotPasswordButton = $('#ModalLoginForgotPasswordBtn');
		this._$loginModalPassword = $('#ModalLoginPassword');
		this._$loginModalSignupButton = $('#ModalLoginCreateAccountBtn');
		this._$loginModalUsername = $('#ModalLoginUsername');
		this._$signupButton = $('#SignUpMobile');
		this._$videoMuteButton = $('#MobileYouToggleVideo');
		this._$helpCenterButton = $('#HelpCenter');
		return this;
	}

	_initializeEventHandlers() {
		const me = this;
		this._$chatButton.unbind(CLICK).click(() => { this.onChatButtonClicked(); return false; });
		this._$hamburgerButton.unbind(CLICK).click(() => { this.onHamburgerClicked(); return false; });
		this._$loginButton.unbind(CLICK).click(() => { this.onLoginClicked(); return false; });
		this._$loginModalSignupButton.unbind(CLICK).click(() => { this.onSignupClicked(); return false; });
		this._$signupButton.unbind(CLICK).click(() => { this.onSignupClicked(); return false; });
		$('#ModalExplanation,#InviteShowingUsersModal,#ModalLogin,#ModalContactUs,#SaveRoom,#ModalChat').find('.close').unbind(CLICK).click(() => { this.onModalCloseClicked(); return false; });
		$('#ModalExplanationLoginBtn,#SaveRoomLoginBtn').unbind(CLICK).click(() => { this.onLoginClicked(); return false; });
		$('#CloseSaveRoomBtn,#CloseExplanationBtn').unbind(CLICK).click(() => { this.onModalCloseClicked(); return false; });
		this._$inviteButton.unbind(CLICK).click(() => { this.onHeaderInviteClicked(); return false; });
		this._$saveRoomButton.unbind(CLICK).click(() => { this.onSaveRoomClicked(); return false; });
		this._$loginModalForgotPasswordButton.unbind(CLICK).click(() => { this.onForgotPasswordClicked();  return false; });
		this._$buildingApplyNowButton.unbind(CLICK).click(() => {
			window.mobileWebController.recordCallToAction('ApplyNow');
			window.open(this.headerMenuModel.buildingApplyNowUrl, '_blank');
			this.onModalCloseClicked();
			return false;
		});
		this._$buildingBookingButton.unbind(CLICK).click(() => {
			window.mobileWebController.recordCallToAction('BookATour');
			window.open(this.headerMenuModel.buildingBookingUrl, '_blank');
			this.onModalCloseClicked();
			return false;
		});
		const _handleAskChatSend = function () {
			const _$input = $('#DialogAskChat');
			const messageText = _$input.val();
			if (messageText) {
				_$input.val('');
				window.mobileWebController.recordCallToAction('AskChat');
				window.mobileWebController.sendChatMessageAndShowChat(messageText);
			}
		}
		$('#DialogAskChatBtn').unbind(CLICK).bind(CLICK, () => {
			_handleAskChatSend();
		});
		$('#DialogAskChat').unbind('keydown').bind('keydown', (evt) => {
			if (evt?.keyCode === 13) {
				setTimeout(_handleAskChatSend, 0);
			}
		});
		$('#SaveRoomCreateAccountBtn').unbind(CLICK).click(() => { this.onSignupClicked(); return false; });

		this._$helpCenterButton.on('click', () => {
			onboarding.showHelp();
			this.onHamburgerClicked();
		});

		return this._initializeLoginModal()._initializeInviteModal()._initializeIdentifyDialog();
	}

	_initializeIdentifyDialog() {
		const $nameTextbox = this._identifyDialog.$nameTextbox;
		const $doneButton = this._identifyDialog.$doneButton;
		const $skipButton = this._identifyDialog.$skipButton;
		const onSubmit = (evt) => {
			evt.preventDefault();
			evt.returnValue = false;
			const name = $nameTextbox.val().trim();
			if (name.length > 0) {
				this.onIdentified(name);
				this.onIdentifyModalSuccess();
			}
			return false;
		};
		const onSkip = (evt) => {
			evt.preventDefault();
			evt.returnValue = false;
			this.onIdentified(window.mobileWebController.userModel.getDisplayNameOrDefault());
			this.onIdentifyModalSkipped();
			return false;
		};
		const onInput = () => {
			$doneButton.toggleClass('form-submit-button-disabled', 0 === $nameTextbox.val().trim().length);
		};
		onInput();
		$nameTextbox.on('input', onInput);
		this._identifyDialog.$loginButton.unbind(CLICK).click(() => { this.onLoginClicked(); return false; });
		$doneButton.unbind(CLICK).click(onSubmit);
		$skipButton.unbind(CLICK).click(onSkip);
		this._identifyDialog.$dialog.find('form').on('submit', onSubmit);
		return this;
	}

	_initializeInviteModal() {
		// TODO - some of this logic needs to move to controller. And also be cleaned up.
		const $inviteSections = $('#InviteSections');
		$('#InviteTabs > div').unbind(CLICK).click((evt) => {
			const $button = $(evt.target);
			const index = $button.index();
			$button.parent().children().removeClass('selected').eq(index).addClass('selected');
			$inviteSections.children().removeClass('selected').eq(index).addClass('selected');
			return false;
		});
		$('#InviteShowingLink').val(getShareLink());
		$('#InviteDoneBtn').unbind(CLICK).click(() => { this.onModalCloseClicked(); return false; });
		$("#InviteShowingCopyLinkBtn").unbind("click").click(function (e) {
			var el = document.getElementById("InviteShowingLink");
			el.contentEditable = true;
			el.readOnly = false;
			el.select();
			document.execCommand("copy");
			el.readOnly = true;
			el.contentEditable = false;
			doToast(TOAST_TYPES.info, 'Link Copied');
		});
		$('#InviteShowingSendSMSBtn').unbind(CLICK).click(() => {
			const phoneNumbers = getPhoneNumbersFromString($('#RecipientUsersPhoneNumber').val());
			if (!phoneNumbers.success) {
				doToast(TOAST_TYPES.error, phoneNumbers.phoneNumbers.length ? 'Invalid Recipient Phone' : 'Please Add Recipient Phone Number');
				return false;
			}
			let smsMessage = $('#InviteShowingSMSBody').val().trim();
			if (!smsMessage) {
				smsMessage = `Join me, ${window.mobileWebController.userModel.displayName}, in this Infinityy Room by clicking the link below`;
			}
			getShortenedUrl(getInfinityyViewerUrl({contentOnlyLink: true})).then((urlAndKey) => {
				smsMessage += "\n" + urlAndKey.url;
				phoneNumbers.phoneNumbers.forEach((pn) => {
					sendSmsMessage(smsMessage, pn);
					createLead({
						authToken: window.mobileWebController.userModel.authToken,
						name: pn,
						phone: pn,
						ownerUsername: window.mobileWebController.userModel.username,
					});
				});
				doToast(TOAST_TYPES.info, 'SMS Invite Sent!');
			});
			this.onModalCloseClicked();
			return false;
		});
		$("#InviteShowingCreateLinkBtn").unbind(CLICK).click(() => {
			const emailAddresses = getEmailAddressesFromString($('#RecipientUsersEmails').val());
			if (!emailAddresses.success) {
				doToast(TOAST_TYPES.error, emailAddresses.emailAddresses.length ? 'Please Add Recipient Emails' : 'Invalid Recipient Email');
				return false;
			}
			getChatInviteInfo(this.headerMenuModel.contentId, window.mobileWebController.userModel.username).then((chatInviteInfo) => {
				const emailContent = prepEmailContent(chatInviteInfo, window.mobileWebController.userModel.displayName, window.mobileWebController.userModel.username);
				emailAddresses.emailAddresses.forEach((emailAddress) => {
					const longUrl = getInfinityyViewerUrl({ contentOnlyLink: true, emailAddress: emailAddress });
					getShortenedUrl(longUrl).then((urlAndKey) => {
						sendEmailMessage(emailContent, urlAndKey.url, emailAddress);
						createLead({
							authToken: window.mobileWebController.userModel.authToken,
							name: emailAddress,
							email: emailAddress,
							ownerUsername: window.mobileWebController.userModel.username
						});
					});
				});
			});
			this.onModalCloseClicked();
			return false;
		});
		return this;
	}

	_initializeLoginModal() {
		this._$loginModalButton.unbind(CLICK).click(() => {
			const username = this._$loginModalUsername.val();
			const password = this._$loginModalPassword.val();
			this._$loginModalPassword.val('');
			this.onLogin(username, password);
			return false;
		});
		return this;
	}
}

export { HeaderMenuView }
