import "core-js"
import { getTabClientInstanceId } from "./utils/identity.es6.js"

import { GAHandler } from './activity/ga-handler.mjs'
import { ActivityMonitor } from './activity/activity-monitor.es6.js'
import { IrApi } from './api/api.es6.js'

import { ContentModel } from "./models/content-model.es6.js"
import { PipsModel } from "./pips/pips-model.es6.js"
import { ChatModel } from "./chat/chat-model.es6.js"
import { UserModel } from "./models/user-model.es6.js"
import { IrSession } from './models/ir-session.es12.js'

import { BottomDrawerView } from "./bottom-drawer/bottom-drawer-view.es6.js"
import { ChatView } from "./chat/chat-view.es6.js"
import { PipsView } from "./pips/pips-view.es6.js"
import { ViewerView } from './viewer/viewer-view.es6.js'

import { MobileWebController } from "./mobile-web-controller.es6.js"
import { LeadModel } from './models/lead-model.es12.js'
import { LeadCaptureView } from './lead-capture-view.es13.js'

import { PreloadContent } from './utils/preload-content.es13';
import { AiService } from './ai/ai-service.mjs';

// These need to be cleaned up.
import { getViewerState, setViewWithConversion } from "./telemetry/room.js"
import { receiveTelemetry } from "./telemetry/rooms-channel.js"
import { ViewerIframeApi } from './viewer/viewer-iframe-api.es.js'

import { onboarding } from './onboarding/onboarding.mjs';

/**
 * Various scripts load async. Logs messages about async scripts being already loaded or logs when they load later, and potentially runs
 * a function `onLoad` when they're loaded (whether they're loaded before or after this call).
 * The lowercase version (eventName) may not be caught by fired after this code loads. In any event, an uppercase version
 * (ie LOADED:GOOGLE-MAPS) will always be fired after these deferred javascripts are loaded (after DOM loaded).
 * @param {string} eventName The event name fired by the script's onload function.
 * @param {string} logName What the script will be called in log/diagnostics messages about it's loading.
 * @param {function} preloadedCheck Function to check if the script has _already_ loaded before processing has reached here.
 * @param {function?} onLoad
 */
function handleAsyncScriptLoading(eventName, logName, preloadedCheck, onLoad) {
	if (preloadedCheck()) {
		console.debug(`[load] ${logName} preloaded.`, Date.now() * .001);
		onLoad && onLoad();
		$('body').trigger(eventName.toUpperCase());
	} else {
		const handler = function () {
			console.debug(`[load] ${logName} loaded.`, Date.now() * .001);
			window.removeEventListener(eventName, handler);
			onLoad && onLoad();
			$('body').trigger(eventName.toUpperCase());
		};
		window.addEventListener(eventName, handler);
	}
}

function handleAllAsyncScriptLoads() {
	handleAsyncScriptLoading('loaded:google-maps', 'Google Maps SDK', () => { return ('undefined' !== typeof (google)) && google.maps; });
	handleAsyncScriptLoading('loaded:matterport-showcase', 'Matterport Showcase SDK', () => { return 'undefined' !== typeof (MP_SDK); });
	handleAsyncScriptLoading('loaded:twilio-video', 'Twilio Video SDK', () => { return ('undefined' !== typeof (Twilio)) && Twilio.Video; },
		() => { window.twilioVideo = Twilio.Video; });
	handleAsyncScriptLoading('loaded:callosum', 'Callosum Library', () => { return ('undefined' !== typeof (EveryScape)) && EveryScape.Callosum },
		() => { window.callosum = EveryScape.Callosum; });
}

function initializeMobileWebViewer(irApi, session, preloadContent) {
	window.clientInstanceId = getTabClientInstanceId(window.siteSettings.WindowNameClientInstanceIdPrefix);
	const initialUrl = window.pageInitialization.initialUrl;
	window.brandId = initialUrl.brandSlug;
	window.contentId = { type: null, id: initialUrl.contentGuid };
	window.useWhereMode = false;
	window.viewerIframeApi = new ViewerIframeApi({ viewerModel: { chatId: window.roomId } });

	window.contentModel = new ContentModel(window.brandId, window.contentId);
	window.leadModel = new LeadModel({ session: session, settings: window.siteSettings }).initialize();
	window.pipsModel = new PipsModel();
	window.chatModel = new ChatModel(window.roomId);
	window.sessionModel = session;
	window.userModel = new UserModel();

	window.activityMonitor = new ActivityMonitor({
		clientInstanceId: window.clientInstanceId,
		contentModel: window.contentModel,
		leadModel: window.leadModel,
		infinityyApiUrl: window.siteSettings.infinityy.api.url,
		roomId: +(window.roomId),
		session: session,
		settings: window.siteSettings,
		userModel: window.userModel
	});

	window.aiService = new AiService({
		settings: {
			aiService: {
				url: window.siteSettings.infinityy.aiService.url
			}
		}
	});
	window.bottomDrawerView = new BottomDrawerView({
		leadModel: window.leadModel
	});
	window.chatView = new ChatView(window.chatModel);
	window.leadCaptureView = new LeadCaptureView({
		activityMonitor: window.activityMonitor,
		api: irApi,
		contentModel: window.contentModel,
		leadModel: window.leadModel,
		userModel: window.userModel
	});
	window.pipsView = new PipsView(window.pipsModel, window.clientInstanceId);
	window.viewerView = new ViewerView({contentModel: window.contentModel, leadModel: window.leadModel});
	window.mobileWebController = new MobileWebController({
		activityMonitor: window.activityMonitor,
		aiService: window.aiService,
		api: irApi,
		clientInstanceId: window.clientInstanceId,
		contentModel: window.contentModel,
		chatModel: window.chatModel,
		initialUrl: initialUrl,
		leadModel: window.leadModel,
		pipsModel: window.pipsModel,
		sessionModel: window.sessionModel,
		userModel: window.userModel,
		leadCaptureView: window.leadCaptureView,
		bottomDrawerView: window.bottomDrawerView,
		chatView: window.chatView,
		pipsView: window.pipsView,
		preloadContent: preloadContent,
		viewerView: window.viewerView
	});
	window.mobileWebController.setNewRoomId(roomId);
	window.mobileWebController.loadContent();
}

function startupPage() {
	const api = new IrApi(window.siteSettings.infinityy.api.url);
	const session = new IrSession({ api: api, settings: window.infinityyEnvironmentSettings });
	const preloadContent = new PreloadContent(session, gaHandler)
	api.getPageInitializationData(session.clientTokenV4)
		.then((pageInitialData) => {
			window.roomId = pageInitialData?.room?.id;
			session.setIdentity(pageInitialData?.user);
			onboarding.identifyUser(`identityId:${session.identityId}`);
			preloadContent.recordPageView(pageInitialData?.content);
			initializeMobileWebViewer(api, session, preloadContent);
		});
	window.toggleHelp = () => { onboarding.toggleHelp(); }
}

const gaHandler = new GAHandler(window.siteSettings?.GoogleAnalyticsSiteId);
gaHandler.initialize();
// There are some places still using global gtag(); one of the API classes
// and Diagnostics (used by conference calling). For now, just make sure that
// global is in place. Later, refactor those 2 places to not use global.
window.gtag = (ev, name, data) => {
	gaHandler.gtag(ev, name, data);
};
handleAllAsyncScriptLoads();
startupPage();

/** TODO: cleanup code below here; telemetry and call coordination with (controller for) the UI */

window.getViewerState = getViewerState;
window.setViewWithConversion = setViewWithConversion;

$('#StopFollowingAction').unbind('click').click(() => {
	window.mobileWebController.guideState.stopFollowing();
	window.mobileWebController.guideState.stopGuidingAll();
	return false;
});

receiveTelemetry(function (data) {
	window.mobileWebController.handleReceivedTelemetry(data);
});
